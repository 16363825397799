<template>
  <template v-if="initial || user.role === 'member'" />
  <div v-else class="p-2 md:p-4 rounded-2xl bg-zinc-900 shadow-lg">
    <h2 class="text-sm text-zinc-500">Google Ads</h2>

    <ClientOnly>
      <template v-if="!pending">
        <component
          is="script"
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6186757245107698"
          async
          crossorigin="anonymous"
        />
        <ins
          class="adsbygoogle ads"
          :style="{ 'max-height': ads[type].maxHeight + 'px' }"
          :data-ad-client="ads[type].client"
          :data-ad-slot="ads[type].slot"
          :data-ad-format="ads[type].format"
          :data-full-width-responsive="ads[type].responsive"
        />
        <component is="script"> (adsbygoogle = window.adsbygoogle || []).push({}); </component>
      </template>
    </ClientOnly>
  </div>
</template>

<script setup>
const { user } = storeToRefs(useGlobalStore())

const pending = ref(true)
const initial = ref(true)

const { type } = defineProps({
  type: {
    type: String,
    default: 'home'
  }
})

const ads = {
  home: {
    client: 'ca-pub-6186757245107698',
    slot: '4101879923',
    format: 'horizontal',
    responsive: true,
    maxHeight: 200
  },
  social: {
    client: 'ca-pub-6186757245107698',
    slot: '3466056300',
    format: 'rectangle',
    responsive: true,
    maxHeight: 300
  },
  deck: {
    client: 'ca-pub-6186757245107698',
    slot: '6887260717',
    format: 'rectangle',
    responsive: true,
    maxHeight: 300
  },
  series: {
    client: 'ca-pub-6186757245107698',
    slot: '6695689020',
    format: 'rectangle',
    responsive: true,
    maxHeight: 300
  }
}

onMounted(() => {
  setTimeout(() => {
    initial.value = false
  }, 100)
  setTimeout(() => {
    pending.value = false
  }, 3000)
})
</script>

<style lang="sass" scoped>
.ads
  @apply flex items-center justify-center h-full w-full
</style>
